import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "crafted-reading---the-business-of-games",
      "style": {
        "position": "relative"
      }
    }}>{`Crafted Reading - The Business of Games`}<a parentName="h1" {...{
        "href": "#crafted-reading---the-business-of-games",
        "aria-label": "crafted reading   the business of games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://en.wikipedia.org/wiki/Video_game_crash_of_1983" mdxType="Link">
        <h4 {...{
          "id": "the-video-game-crash-of-1983",
          "style": {
            "position": "relative"
          }
        }}>{`The Video Game Crash of 1983`}<a parentName="h4" {...{
            "href": "#the-video-game-crash-of-1983",
            "aria-label": "the video game crash of 1983 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`The fascinating history of video games goes back way before our 1997 timeline. One notable event was the Atari Shock, a great example of an industry disruption.`}</p>
      </Link>
      <Link to="https://www.superdataresearch.com/blog/2020-year-in-review" mdxType="Link">
        <h4 {...{
          "id": "superdatas-2020-year-in-review",
          "style": {
            "position": "relative"
          }
        }}>{`SuperData's 2020 Year in Review`}<a parentName="h4" {...{
            "href": "#superdatas-2020-year-in-review",
            "aria-label": "superdatas 2020 year in review permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`At the start of 2020, video games received significant pandemic-fueled growth, growing 12% year-over-year compared to last year's 3%. Superdata's annual research is a good look into the video game industry and its subsectors.`}</p>
      </Link>
      <Link to="https://www.macworld.com/article/1142857/pspgo.html" mdxType="Link">
        <h4 {...{
          "id": "psp-mini-falls",
          "style": {
            "position": "relative"
          }
        }}>{`PSP Mini Falls`}<a parentName="h4" {...{
            "href": "#psp-mini-falls",
            "aria-label": "psp mini falls permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`The PSP Mini is one of those game technologies that were left behind in a disruption. Even the backing of a huge conglomerate couldn't save the console, as smart phones became more powerful and developer-friendly. `}</p>
      </Link>
      <Link to="https://www.kickstarter.com/projects/ouya/ouya-a-new-kind-of-video-game-console" mdxType="Link">
        <h4 {...{
          "id": "the-ouya-kickstarter",
          "style": {
            "position": "relative"
          }
        }}>{`The Ouya Kickstarter`}<a parentName="h4" {...{
            "href": "#the-ouya-kickstarter",
            "aria-label": "the ouya kickstarter permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A valiant effort to disrupt the console market, a small team decides to create a new Android-based games console. It ultimately fails and `}<Link to="https://techcrunch.com/2019/05/22/seven-years-later-the-ouya-is-dead-for-real/" mdxType="Link">{`is sold to Razer`}</Link>{`, but for a time it was the symbol of an independent, open platform.`}</p>
      </Link>
      <Link to="https://www.gamesindustry.biz/articles/2016-05-11-the-death-of-toys-to-life" mdxType="Link">
        <h4 {...{
          "id": "the-toys-to-life-bubble",
          "style": {
            "position": "relative"
          }
        }}>{`The Toys-To-Life Bubble`}<a parentName="h4" {...{
            "href": "#the-toys-to-life-bubble",
            "aria-label": "the toys to life bubble permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Activision's Skylanders found success with this genre of games integrated with physical toys. Other publishers piled on the genre until the bubble burst in 2016, culminating in huge game studios being abruptly shut down.`}</p>
      </Link>
      <Link to="https://wtfisqf.com/" mdxType="Link">
        <h4 {...{
          "id": "wtf-is-qf",
          "style": {
            "position": "relative"
          }
        }}>{`WTF is QF?`}<a parentName="h4" {...{
            "href": "#wtf-is-qf",
            "aria-label": "wtf is qf permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A primer on Quadratic Funding, one of the tools we now have to be able to remodel our game economies.`}</p>
      </Link>
    </List>
    <p>{`Please contribute more with a PR! 📝`}</p>
    <h2 {...{
      "id": "breakout-activity",
      "style": {
        "position": "relative"
      }
    }}>{`Breakout Activity`}<a parentName="h2" {...{
        "href": "#breakout-activity",
        "aria-label": "breakout activity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`For this week, we'll be using Tableau and Google Sheets to create a similar timeline of relevant articles for our Web 3 projects.`}</p>
    <List mdxType="List">
      <Link to="https://public.tableau.com/profile/paul.gadi#!/vizhome/GameIndustryTimeline/GameIndustryTimelinebasedonselectGamasutraArticles" mdxType="Link">
        <p>{`The Tableau Project`}</p>
      </Link>
      <Link to="https://docs.google.com/spreadsheets/d/1NwNkNDqk31cKXO5e5vm3xWs4ieUU-sgot_7hu6f4pmg/edit#gid=0" mdxType="Link">
        <p>{`Google Sheet`}</p>
      </Link>
    </List>
    <p>{`Make a copy of each one, and then go to a relevant industry news source. Do a search for topics / technologies related to your project (for NFT projects for example, some valid searches are "virtual items", "art", "generative graphics"), and then curate at least one article for each of the last 10 years.`}</p>
    <p>{`Once done, please share your Tableau project on Slack. It will be useful learning also for your peers! 🙌`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      